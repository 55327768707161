import React, { useEffect } from "react";
// Aggiungi questa riga
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "./pornoglass.css";
import NavbarPorn from "../NavbarPorn";

import Pornoheader from "./pornoheader";
import Whitepaper from "./PornoWhitepaper";
import RoadMaps from "./PornoRoadMaps";
import Contact from "./PornoTouch";
// Importa altri componenti per le diverse sezioni

const PornIndex = ({ hideNavbar }) => {
  useEffect(() => {
    // Controlla se hideNavbar è una funzione prima di chiamarla
    if (typeof hideNavbar === "function") {
      hideNavbar(); // Chiama la funzione hideNavbar per nascondere la navbar
    }
  }, [hideNavbar]);

  return (
    <div>
      <Router>
        <div className="custom-cursor-2">
          <NavbarPorn />

          <div id="Home">
            <Pornoheader hideNavbar={hideNavbar} />
          </div>

          <div id="Whitepaper">
            <Whitepaper />
          </div>

          <div id="Road-Maps">
            <RoadMaps />
          </div>

          <div id="Contact">
            <Contact />
          </div>

          {/* Aggiungi altri componenti per le diverse sezioni */}
        </div>
      </Router>
    </div>
  );
};

export default PornIndex;
