import React from "react";

export const Team = (props) => {
  return (
    <div id="team" className="text-center">
      <div className="section-title">
        <h2>Friendly Project</h2>
      </div>

      <div className="barcontainer">
        <div className="minilogo img icoovering">
          <a href="https://bueno.art/" target="_blank">
            <img src="img/friendly/mini/bueno.png" alt="BuenoArt" />
          </a>
        </div>
        <div className="minilogo img icoovering">
          <a
            href="https://twitter.com/parliament3_0?s=21&t=L84b-iB3rdKJgKXXAytB_Q"
            target="_blank"
          >
            <img src="img/friendly/mini/silvio.png" alt="Silvio Ethernal" />
          </a>
        </div>

        <div className="minilogo img icoovering">
          <a href="/pornoglass" target="_blank">
            <img src="img/friendly/mini/porno.png" alt="POR.no Glass" />
          </a>
        </div>
        <div className="minilogo img icoovering">
          <a href="https://opensea.io/collection/mugshotrump" target="_blank">
            <img src="img/friendly/mini/mugshotrump.png" alt="MugshoTrump" />
          </a>
        </div>
        <div className="minilogo img icoovering">
          <a
            href="https://twitter.com/duckingidiots?s=21&t=L84b-iB3rdKJgKXXAytB_Q"
            target="_blank"
          >
            <img src="img/friendly/mini/duck.png" alt="Ducking Idiots" />
          </a>
        </div>
        <div className="minilogo img icoovering">
          <a href="https://raritysniper.com/" target="_blank">
            <img src="img/friendly/mini/sniper.png" alt="Rarity Sniper NFT" />
          </a>
        </div>
      </div>
      <div className="container">
        <div className="main-container">
          <div className="logo img icoovering">
            <a href="https://dverso.io/" target="_blank">
              <img src="img/friendly/dverso.png" alt="Dverso" />
            </a>
          </div>
          <div className="logo img icoovering">
            <a href="https://5metalab.com/" target="_blank">
              <img src="img/friendly/5ml.png" alt="5metalab" />
            </a>
          </div>
          <div className="logo img icoovering">
            <a href="https://holyclub.xyz/" target="_blank">
              <img src="img/friendly/holyclub.png" alt="Holy Club" />
            </a>
          </div>
        </div>
      </div>
      <div className="barcontainer">
        <div className="minilogo img icoovering">
          <a href="https://dverso.io/map/supesu-imbeda" target="_blank">
            <img
              src="img/friendly/mini/supesu.png"
              alt="Supesu Imbeda Dverso"
            />
          </a>
        </div>
        <div className="minilogo img icoovering">
          <a href="/donutday" target="_blank">
            <img src="img/friendly/mini/donutday.png" alt="Donut Day" />
          </a>
        </div>
        <div className="minilogo img icoovering">
          <a
            href="https://linktr.ee/juice_of_dionysus?utm_source=linktree_profile_share&ltsid=d4f79183-3429-4790-87fb-93729a9f9b77"
            target="_blank"
          >
            <img src="img/friendly/mini/juice.png" alt="Juice of Dionysus" />
          </a>
        </div>

        <div className="minilogo img icoovering">
          <a href="https://lonfo.net/" target="_blank">
            <img src="img/friendly/mini/lonfo.png" alt="Lonfo" />
          </a>
        </div>
        <div className="minilogo img icoovering">
          <a href="https://wrngbzz.com/" target="_blank">
            <img src="img/friendly/mini/wrng.png" alt="WRNG" />
          </a>
        </div>
        <div className="minilogo img icoovering">
          <a
            href="https://linktr.ee/bjno.hot.line?utm_source=linktree_profile_share&ltsid=8695288e-e0d7-4286-9170-0a568ceb03e7"
            target="_blank"
          >
            <img src="img/friendly/mini/bjno.png" alt="Bjno Hot Line" />
          </a>
        </div>
      </div>
    </div>
  );
};
