import React from "react";
import GalleryItem from "./GalleryItem";
import "./gallerystyle.css";

const Gallery = () => {
  // Creiamo un array di indici che corrisponderanno alle immagini in GalleryItem
  const galleryData = [
    { title: "Dverso.io", imageIndex: 0 },
    { title: "Ducking Idiots", imageIndex: 1 },
    { title: "Cryptomadonne", imageIndex: 2 },
    { title: "Juice of Dionysus", imageIndex: 3 },
    { title: "POR.no Glass - 뽀르노 글라스", imageIndex: 4 },
    { title: "WRNG.bzz", imageIndex: 5 },
    { title: "Supesu Imbeda", imageIndex: 6 },
    { title: "Parliament", imageIndex: 7 },
    { title: "Holy Club", imageIndex: 8 },
    { title: "Holy Club", imageIndex: 9 },
    { title: "Holy Club", imageIndex: 10 },
    { title: "Holy Club", imageIndex: 11 },
  ];

  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Gallery</h2>
        </div>
      </div>
      <div className="gallerycontainerdad">
        <div className="gallery-girl">
          <img src="img/gallerymeme.png" alt="TRUMPONE" className="trumpone" />
        </div>
        <div className="gallery-wrap">
          <div className="gallery-list">
            {galleryData.map((item, index) => (
              <div
                key={`gallery-item-${index}`}
                className="gallery-item-wrapper"
              >
                <GalleryItem
                  singleImageIndex={item.imageIndex} // Passiamo l'indice dell'immagine
                  singleMode={true} // Nuovo flag per indicare che vogliamo una sola immagine
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
