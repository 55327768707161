import React, { useState } from "react";

const GalleryItem = (props) => {
  const smallImages = [
    "img/portfolio/01-small.jpg",
    "img/portfolio/02-small.jpg",
    "img/portfolio/03-small.jpg",
    "img/portfolio/04-small.jpg",
    "img/portfolio/05-small.jpg",
    "img/portfolio/06-small.jpg",
    "img/portfolio/07-small.jpg",
    "img/portfolio/08-small.jpg",
    "img/portfolio/09-small.jpg",
    "img/portfolio/10-small.jpg",
    "img/portfolio/11-small.jpg",
    "img/portfolio/12-small.jpg",
  ];

  const largeImages = [
    "img/portfolio/large/01-large.jpg",
    "img/portfolio/large/02-large.jpg",
    "img/portfolio/large/03-large.jpg",
    "img/portfolio/large/04-large.jpg",
    "img/portfolio/large/05-large.jpg",
    "img/portfolio/large/06-large.jpg",
    "img/portfolio/large/07-large.jpg",
    "img/portfolio/large/08-large.jpg",
    "img/portfolio/large/09-large.jpg",
    "img/portfolio/large/10-large.jpg",
    "img/portfolio/large/11-large.jpg",
    "img/portfolio/large/12-large.jpg",
  ];

  const altDescriptions = [
    "Dverso.io",
    "Ducking Idiots",
    "Cryptomadonne",
    "Juice of Dionysus",
    "POR.no Glass - 뽀르노 글라스",
    "WRNG.bzz",
    "Supesu Imbeda",
    "Parliament",
    "Holy Club",
  ];

  const largeImageDescriptions = [
    "Dverso.io",
    "Ducking Idiots",
    "Cryptomadonne",
    "Juice of Dionysus",
    "POR.no Glass - 뽀르노 글라스",
    "WRNG.bzz",
    "Supesu Imbeda",
    "Parliament",
    "Holy Club",
  ];

  const imageLinks = [
    "https://dverso.io/",
    "https://idiots-club.com/",
    "https://cryptomadonne.com/it//",
    "https://linktr.ee/juice_of_dionysus",
    "#/pornoglass",
    "https://wrngbzz.com/",
    "https://dverso.io/",
    "https://idiots-club.com/",
    "https://holyclub.xyz/",
  ];

  const metaTag = [
    "mateverse, metaworld, metaverso, avatar, droids, rpg, parkour, multiverso, dverso, dverso.io, eth, polygon ",
    "duck, rubber duck, ducking idiots, idiots, yellow duck, nft, eth, funny",
    "holy club, madonne, cryptomadonne, art, pixel art, nft, eth, phisic art",
    "juice, dionysus, pixel, px, pixel art, pixel artist, nft, eth, foundation, mint",
    "korea, corea, porno, art, digital art, 뽀르노 글라스, 포르노, 암호, 블록체인, 대체할 수 없는 토큰, 디지털 아트",
    "carbo, carbowrong, carbonara, balocchi, lotteria, pinocchio, nft, arte, tela, crypto, eth, btc, wrng.bzz, wrng, bzz",
    "space invader, future, supesu imbeda, dverso, game, 3d, 3d artist, 3d art, shot, color, monster, mission, metaverse, metaworld, nft, eth, droids, avatar, buy, game",
    "parliament, parlamento, parlamentari, nft, berlusca, berlusconi, bellusconi, vr, pixel, pixel art, pixel artist, juice of dionysus, collabboration, bjno hot line, bjno, silvio berlusconi, santino, ricordo, mafia, milano 2, mediaset, globaltv",
    "holy club, holy temple, temple, metaverse, digital art, digital gallery, digital expo, gamestop, milano, carnate, brera, metabrera, cryptomadonne, waro, pitmarels, you artist, andrea crespi, then, nft, crypto, mint",
  ];
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const openOverlay = (index) => {
    setSelectedImageIndex(index);
  };

  const closeOverlay = () => {
    setSelectedImageIndex(null);
  };

  const handleNextImage = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex === largeImages.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePreviousImage = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex === 0 ? largeImages.length - 1 : prevIndex - 1
    );
  };

  const handleKeyPress = (event) => {
    if (event.key === "ArrowLeft") {
      handlePreviousImage();
    } else if (event.key === "ArrowRight") {
      handleNextImage();
    }
  };

  return (
    <div className="gallery-item">
      <h3>{props.title}</h3>
      <div className="image-container">
        {props.singleMode ? (
          <img
            src={smallImages[props.singleImageIndex]}
            alt={altDescriptions[props.singleImageIndex]}
            metaTag={metaTag[props.singleImageIndex]}
            onClick={() => openOverlay(props.singleImageIndex)}
          />
        ) : (
          smallImages.map((src, index) => (
            <img
              key={index}
              src={src}
              alt={altDescriptions[index]}
              metaTag={metaTag[index]}
              onClick={() => openOverlay(index)}
            />
          ))
        )}
      </div>
      {selectedImageIndex !== null && (
        <div
          className="overlay"
          onClick={closeOverlay}
          onKeyDown={handleKeyPress}
          tabIndex={0}
        >
          <div className="overlay-content" onClick={(e) => e.stopPropagation()}>
            <img
              src={largeImages[selectedImageIndex]}
              alt={largeImageDescriptions[selectedImageIndex]}
            />
            <div className="close-icon" onClick={closeOverlay}>
              <i className="fa fa-times"></i>
            </div>
            <div className="arrow-icon left" onClick={handlePreviousImage}>
              <i className="fa fa-chevron-left"></i>
            </div>
            <div className="arrow-icon right" onClick={handleNextImage}>
              <i className="fa fa-chevron-right"></i>
            </div>
            <a
              href={imageLinks[selectedImageIndex]}
              target="_blank"
              rel="noopener noreferrer"
            >
              CLICK HERE for some extra fun!
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default GalleryItem;
