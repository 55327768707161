import React from "react";
import "./SocialFooter.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

export const Contact = () => {
  return (
    <div className="social-footer-wrapper">
      <div id="contact">
        <ul className="social-icons-list">
          <li>
            <a
              href="https://twitter.com/idiots__club"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Twitter, X"
              itemProp="sameAs"
            >
              {Array.from({ length: 5 }, (_, index) => (
                <span key={index} className="social-icon">
                  <i className="fab fa-twitter"></i>
                </span>
              ))}
            </a>
          </li>
          <li>
            <a
              href=" https://discord.gg/wse3Z5Yjhx"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Disord"
              itemProp="sameAs"
            >
              {Array.from({ length: 5 }, (_, index) => (
                <span key={index} className="social-icon">
                  <i className="fab fa-discord"></i>
                </span>
              ))}
            </a>
          </li>
          <li>
            <a
              href="https://www.reddit.com/user/IDIOTs-Club/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Reddit"
              itemProp="sameAs"
            >
              {Array.from({ length: 5 }, (_, index) => (
                <span key={index} className="social-icon">
                  <i className="fab fa-reddit"></i>
                </span>
              ))}
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/idiots___club/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Instagram"
              itemProp="sameAs"
            >
              {Array.from({ length: 5 }, (_, index) => (
                <span key={index} className="social-icon">
                  <i className="fab fa-instagram"></i>
                </span>
              ))}
            </a>
          </li>
        </ul>
      </div>
      <div className="copyright-section">
        <p className="colofooter">
          &copy; 2023 IDIOTS CLUB. Design by{" "}
          <a
            className="colobjno"
            href="https://linktr.ee/bjno.hot.line?utm_source=linktree_profile_share&ltsid=436e36ea-63e3-408e-8e6c-da6bad0b47dbm"
            rel="nofollow"
            target="_blank"
          >
            BJÑO Hot Line
          </a>
        </p>
      </div>
    </div>
  );
};
